import PerfectScrollbar from 'perfect-scrollbar';
import Cookies from 'js-cookie'
import Sticky from 'sticky-js'

// Lodash legacy
import {
    assign as _assign,
    camelCase as _camelCase,
    clone as _clone,
    constant as _constant,
    debounce as _debounce,
    each as _each,
    every as _every,
    filter as _filter,
    find as _find,
    forIn as _forIn,
    findIndex as _findIndex,
    findKey as _findKey,
    fromPairs as _fromPairs,
    get as _get,
    groupBy as _groupBy,
    head as _head,
    has as _has,
    includes as _includes,
    isEmpty as _isEmpty,
    isEqual as _isEqual,
    indexOf as _indexOf,
    keys as _keys,
    map as _map,
    mapKeys as _mapKeys,
    merge as _merge,
    orderBy as _orderBy,
    pickBy as _pickBy,
    reduce as _reduce,
    reject as _reject,
    remove as _remove,
    set as _set,
    size as _size,
    some as _some,
    sortBy as _sortBy,
    take as _take,
    uniqBy as _uniqBy,
    update as _update,
    values as _values,
    valuesIn as _valuesIn,
    isFunction as _isFunction
} from 'lodash-es'

// Lodash legacy globals
window._assign = _assign
window._camelCase = _camelCase
window._clone = _clone
window._constant = _constant
window._debounce = _debounce
window._each = _each
window._every = _every
window._filter = _filter
window._find = _find
window._forIn = _forIn
window._findIndex = _findIndex
window._findKey = _findKey
window._fromPairs = _fromPairs
window._groupBy = _groupBy
window._get = _get
window._head = _head
window._has = _has
window._includes = _includes
window._isEmpty = _isEmpty
window._isEqual = _isEqual
window._indexOf = _indexOf
window._keys = _keys
window._map = _map
window._mapKeys = _mapKeys
window._merge = _merge
window._orderBy = _orderBy
window._pickBy = _pickBy
window._reduce = _reduce
window._reject = _reject
window._remove = _remove
window._set = _set
window._size = _size
window._some = _some
window._sortBy = _sortBy
window._take = _take
window._uniqBy = _uniqBy
window._update = _update
window._values = _values
window._valuesIn = _valuesIn
window._isFunction = _isFunction
window._isFunction = _isFunction

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.App = {
    // Screen breakpoints
    screen: {
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1440
    }
}

try {
    window.PerfectScrollbar = PerfectScrollbar;
    window.Cookies = Cookies;
    window.Sticky = Sticky;
} catch (e) {
    console.error(e)
}
