/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import * as Sentry from "@sentry/vue";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

require('./bootstrap');

window.Vue = require('vue');

if (PageData.sentry) {
    Sentry.init({
        Vue: Vue,
        dsn: PageData.sentry,
        environment: PageData.env,
        release: PageData.release,
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampler: samplingContext => {
            if (samplingContext && samplingContext.transactionContext.name.indexOf('health') !== -1) {
                return 0.0;
            } else {
                return 0.5;
            }
        }
    });

    Sentry.configureScope(function(scope) {
        scope.setTag("version", PageData.version);
        scope.setUser({
            userId: PageData.userId ? PageData.userId: null
        });
    });
}
